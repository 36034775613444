/* eslint-disable react/button-has-type */
import React from 'react';
import { Link, withRouter } from 'react-router-dom';
import Axios from 'axios';
import { APIS } from '../api/api';
import Icon from '../Components/icon';
import '../Scss/userdetail.scss';
import { formatDate } from '../Utils/CommonFunction';
class UserDetail extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      userDetail: {},
      loader: false,
      selectedLogCard: 'workHistory',
      organizationLog: [],
    };
  }

  componentDidMount() {
    const { match } = this.props;
    const routerParams = match?.params;
    const userId = routerParams?.userId;
    this.setState({ userId }, () => {
      this.getUserDetails();
    });
  }

  getUserDetails() {
    const { userId } = this.state;
    this.setState({ loader: true });
    const APIURL = APIS.get_url('GET_USERDETAILS', userId);
    Axios({
      method: 'get',
      url: APIURL,
      responseType: 'json',
      timeout: 60 * 1000,
      headers: {
        'X-Username': 'santhosh',
      },
    }).then((res) => {
      this.setState({ userDetail: res.data, loader: false });
    });
  }
  editUserDetails() {
    this.props.history.push(`/add-user/${this.state.userId}`);
  }
  editWelfare() {
    this.props.history.push(`/add-user/welfare/${this.state.userId}`);
  }
  setSelectedLogCard(selectedLogCard) {
    this.setState({ selectedLogCard });
  }
  createIdCard() {
    const { userId } = this.state;
    this.setState({ loader: true });
    const APIURL = APIS.get_url('CREATE_ID_CARD', userId);
    Axios({
      method: 'get',
      url: APIURL,
      responseType: 'json',
      timeout: 60 * 1000,
    })
      .then((res) => {
        this.setState({ loader: false });
        window.location.reload();
      })
      .catch((err) => {
        this.setState({ loader: false });
        window.location.reload();
      });
  }
  render() {
    const { userDetail, selectedLogCard, organizationLog } = this.state;
    const workHistory =
      userDetail && userDetail.work_history ? userDetail.work_history : [];
    return (
      <div className='user-detail'>
        {this.state.loader && <div className='loader'></div>}
        <div className='breadcrumb'>
          <div className='breadcrumb-value'>
            <Link to='/add-user'>User Detail</Link>
            <Icon name='next' className='next-icon' />
            <div className='username'>{userDetail.first_name}</div>
          </div>
          <div className='tools-holder'>
            {userDetail.admin_approve_status === 'active' && (
              <button
                className='btn edi-profile'
                onClick={() => this.createIdCard()}
              >
                <Icon name='edit' />
                Create Id Card
              </button>
            )}
            <button
              className='btn edi-profile'
              onClick={() => this.editUserDetails()}
            >
              <Icon name='edit' />
              Edit Profile
            </button>
            <button
              className='btn edi-profile'
              onClick={() => this.editWelfare()}
            >
              <Icon name='insurance' />
              Add Welfare
            </button>
          </div>
        </div>
        <div className='user-content-holder'>
          <div className='w-68 d-flex profile-holder'>
            <div className='w-30 profile-image-card'>
              <div className='w-50 image-holder'>
                <img src={userDetail.profile_image} alt='profile' />
              </div>
              <div className='profile-name'>
                {userDetail.first_name} {userDetail.last_name}{' '}
              </div>
              <div className='side-header'>{userDetail.email_id}</div>
              <div className='d-flex occupation-data'>
                <div className='w-50 first-child'>
                  {userDetail.designation}
                  <div className='side-header'>
                    {formatDate(userDetail.doj)}
                  </div>
                </div>
                <div className='w-50'>
                  {userDetail.division}
                  <div className='side-header'>{userDetail.office_address}</div>
                </div>
              </div>
            </div>
            <div className='w-69 d-flex profile-details-card'>
              <div className='w-33 meta-holder'>
                <div className='meta-header'>Gender</div>
                <div className='meta-value'>{userDetail.gender}</div>
                <div className='w-80 border-bottom' />
              </div>
              <div className='w-33 meta-holder'>
                <div className='meta-header'>Birthday</div>
                <div className='meta-value'>{formatDate(userDetail.dob)}</div>
                <div className='w-80 border-bottom' />
              </div>
              <div className='w-33 meta-holder'>
                <div className='meta-header'>Mobile Number</div>
                <div className='meta-value'>{userDetail.contact_number}</div>
                <div className='w-80 border-bottom' />
              </div>
              <div className='w-33 meta-holder'>
                <div className='meta-header'>Educational Level</div>
                <div className='meta-value'>{userDetail.qualification}</div>
                <div className='w-80 border-bottom' />
              </div>
              <div className='w-33 meta-holder'>
                <div className='meta-header'>Blood Group</div>
                <div className='meta-value'>{userDetail.blood_group}</div>
                <div className='w-80 border-bottom' />
              </div>
              <div className='w-33 meta-holder'>
                <div className='meta-header'>Joining Date</div>
                <div className='meta-value'>{formatDate(userDetail.doj)}</div>
                <div className='w-80 border-bottom' />
              </div>
              <div className='w-33 meta-holder'>
                <div className='meta-header'>Residential Address</div>
                <div className='meta-value'>
                  {userDetail.residential_address}
                </div>
                <div className='w-80 border-bottom' />
              </div>
              <div className='w-33 meta-holder'>
                <div className='meta-header'>City</div>
                <div className='meta-value'>{userDetail.city}</div>
                <div className='w-80 border-bottom' />
              </div>
              <div className='w-33 meta-holder'>
                <div className='meta-header'>Pincode</div>
                <div className='meta-value'>{userDetail.pincode}</div>
                <div className='w-80 border-bottom' />
              </div>
            </div>
            <div className='w-100 user-log-card'>
              <div className='toggle-holder  d-flex'>
                <div
                  className={
                    'toggle-button' +
                    (selectedLogCard === 'workHistory' ? ' selected' : '')
                  }
                  onClick={() => this.setSelectedLogCard('workHistory')}
                >
                  Work History
                </div>
                <div
                  className={
                    'toggle-button' +
                    (selectedLogCard === 'organizationHistory'
                      ? ' selected'
                      : '')
                  }
                  onClick={() => this.setSelectedLogCard('organizationHistory')}
                >
                  Organization History
                </div>
              </div>
              <div className='w-100 user-log-details '>
                {selectedLogCard === 'organizationHistory' &&
                  organizationLog.length > 0 &&
                  organizationLog.map((log) => (
                    <div className='log-holder d-flex'>
                      <div className='w-10 side-line-holder'>
                        <div className='circle'></div>
                        <div className='line'></div>
                      </div>
                      <div className='w-90 log-data-holder'>
                        <div className='log-data-card d-flex'>
                          <div className='date'>01 Jan 2020 </div>
                          <div className='action-details-holder'>
                            <div className='action'>Action</div>
                            <div className='action-details'>
                              Joined Organisation
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  ))}
                {selectedLogCard === 'workHistory' &&
  workHistory &&
  typeof workHistory === 'string' &&
  JSON.parse(workHistory).length > 0 &&
  JSON.parse(workHistory).map((history) => (
    <div className='log-holder d-flex'>
      <div className='w-10 side-line-holder'>
        <div className='circle'></div>
        <div className='line'></div>
      </div>
      <div className='w-90 log-data-holder'>
        <div className='log-data-card d-flex'>
          <div className='log-value-holder'>
            <div className='meta-header'>Designation</div>
            <div className='meta-value'>
              {history.designation}
            </div>
          </div>
          <div className='log-value-holder'>
            <div className='meta-header'>Date of Joining</div>
            <div className='meta-value'>
              {formatDate(history.doj)}
            </div>
          </div>
          {history.dor && (
            <div className='log-value-holder'>
              <div className='meta-header'>
                Date of relieving
              </div>
              <div className='meta-value'>
                {formatDate(history.dor)}
              </div>
            </div>
          )}
          <div className='log-value-holder'>
            <div className='meta-header'>Office Address</div>
            <div className='meta-value'>
              {history.office_address}
            </div>
          </div>
          <div className='log-value-holder'>
            <div className='meta-header'>Circle Of Working</div>
            <div className='meta-value'>{history.division}</div>
          </div>
        </div>
      </div>
    </div>
  ))}
              </div>
            </div>
          </div>
          <div className='w-32 other-holder'>
            <div className='files-card'>
              <div className='files-header d-flex'>
                Documents
                {/* <div className='add-file d-flex'>
                  <Icon name='add-file' className='add-file' />
                  Add Files
                </div> */}
              </div>
              {userDetail.admin_approve_status === 'active' && (
                <a href={userDetail.member_id_card} target='_blank'>
                  Download Id Card
                </a>
              )}
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default withRouter(UserDetail);
