import React from 'react';
import { withRouter, Route, Redirect, Switch } from 'react-router-dom';
import './App.scss';
import SidebarComponent from './Components/sidebar';
import ViewArea from './Views/ViewArea';
import Signin from './Views/login';
import AddUser from './Views/add-user'; // Import AddUser
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

const ProtectedRoute = ({ component: Component, ...rest }) => {
  return (
    <Route
      {...rest}
      render={(props) => {
        if (localStorage.getItem('userdetails')) {
          return <Component {...props} />;
        } else {
          return <Redirect to="/login" />;
        }
      }}
    />
  );
};

class App extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      isPanelOpen: false,
    };
    this.onPanelexpand = this.onPanelexpand.bind(this);
  }

  onPanelexpand(isPanelOpen) {
    this.setState({ isPanelOpen });
  }

  render() {
    const { isPanelOpen } = this.state;
    return (
      <div className="app-container">
        <ToastContainer
          className="toast-container"
          position="top-center"
          autoClose={5000}
          hideProgressBar={true}
          newestOnTop={false}
          closeOnClick
          closeButton={false}
          rtl={false}
          pauseOnFocusLoss
          draggable
          pauseOnHover
        />
        <Switch>
          <Route exact path="/login" component={Signin} />
          <Route exact path="/add-user" component={AddUser} />
          <ProtectedRoute path="/" component={() => (
            <React.Fragment>
              <SidebarComponent onPanelexpand={this.onPanelexpand} />
              <ViewArea isPanelOpen={isPanelOpen} />
            </React.Fragment>
          )} />
        </Switch>
      </div>
    );
  }
}

export default withRouter(App);