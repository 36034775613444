/* eslint-disable class-methods-use-this */
import React from 'react';
import TextField from '@material-ui/core/TextField';
import { withRouter } from 'react-router-dom';
import Axios from 'axios';
import '../Scss/add-user.scss';
import { APIS } from '../api/api';
import DropZone from '../Components/dropzone';
import Icon from '../Components/icon';
import DropDown from '../Components/dropdown';
import { districts } from '../constants/district';
import { divisions } from '../constants/division';
import { toastEmitter } from '../Utils/CommonFunction';

class AddUser extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            userId: null,
            nestedWorkHistoryCount: 0,
            profileImageSrc: '',
            formElements: [],
            welfare: false,
            welfarePutMethod: false,
            updatedKey: [],
            work_history: [
                {
                    doj: '',
                    dor: '',
                    designation: '',
                    office_address: '',
                    division: '',
                },
            ],
        };
    }

    componentDidMount() {
        const { match } = this.props;
        const userDetailsString = localStorage.getItem('userdetails');
        let userData = null;

        if (userDetailsString) {
            try {
                const userDetail = JSON.parse(userDetailsString);
                userData = userDetail.userData;
            } catch (error) {
                console.error("Error parsing userdetails from localStorage:", error);
            }
        }

        const routerParams = match?.params;
        const userId = routerParams?.userId;
        const welfare = routerParams?.welfare;
        let nominees;
        let formElements = [
            {
                formHeader: 'Personal Details',
                formFields: [
                    { label: 'First name', key: 'first_name' },
                    { label: 'Last name', key: 'last_name' },
                    { label: 'DOB', key: 'dob', type: 'date' },
                    {
                        label: 'Relationship',
                        key: 'relationship',
                        type: 'dropdown',
                        options: [
                            { value: 'S/O', label: 'S/O' },
                            { value: 'D/O', label: 'D/O' },
                            { value: 'W/O', label: 'W/O' },
                            { value: 'H/O', label: 'H/O' },
                        ],
                    },
                    { label: 'Father/Husband Name', key: 'nominee_name' },
                ],
            },
            {
                formHeader: 'Contact Details',
                formFields: [
                    { label: 'Contact Number', key: 'contact_number' },
                    { label: 'Email', key: 'email' },
                    { label: 'Residential Address', key: 'residential_address' },
                    { label: 'City', key: 'city', type: 'dropdown', options: districts },
                    { label: 'Pincode', key: 'pincode' },
                ],
            },
            {
                formHeader: 'Other Details',
                formFields: [
                    { label: 'Enrollment Date', key: 'enrollment_date', type: 'date' },
                    { label: 'Education Level', key: 'qualification' },
                    {
                        label: 'Auto subscription',
                        key: 'subscription',
                        type: 'dropdown',
                        options: [{ label: 'Auto', value: true }],
                    },
                    { label: 'Blood Group', key: 'blood_group' },
                    {
                        label: 'Gender',
                        key: 'gender',
                        type: 'dropdown',
                        options: [
                            { value: 'Male', label: 'Male' },
                            { value: 'Female', label: 'Female' },
                        ],
                    },
                ],
            },
        ];

        if (userData && userData.user_type === 'super_admin') {
            const adminOptions = {
                formHeader: 'Admin Options',
                formFields: [
                    {
                        label: 'User Type',
                        key: 'user_type',
                        type: 'dropdown',
                        options: [
                            { label: 'Admin', value: 'admin' },
                            { label: 'Super Admin', value: 'super_admin' },
                            { label: 'District Admin', value: 'district_admin' },
                        ],
                    },
                ],
            };
            formElements.push(adminOptions);
        }

        if (welfare) {
            nominees = [{}, {}, {}, {}, {}];
            formElements = [
                {
                    formHeader: 'Details',
                    formFields: [
                        { label: 'GPF / CPS NO', key: 'gps_cps_no' },
                        { label: 'Aadhar No', key: 'aadhar_no' },
                        { label: 'Permanent Address', key: 'permanent_address' },
                        {
                            label: 'Marital status',
                            key: 'married',
                            type: 'dropdown',
                            options: ['Single', 'Married'],
                        },
                        { label: 'Nominee Name', key: 'hushband_wife_name' },
                    ],
                },
            ];
        }

        this.setState({ userId, formElements, welfare, nominees }, () => {
             console.log("Divisions in componentDidMount:", this.state.work_history.divisions);
            if (userId) this.getUserDetails();
        });
    }

    // ... (rest of the methods: getUserDetails, getWelfare, getAddExtraWorkBtn, updateFormValue, addExtraWork, uploadProfileImage, removeFile, handleOptionChange, updateUser, adduser, downloadIdCard, addWelfare, getFormElement, renderFormFields, renderFormCard, renderFormHolder, renderWelfareElement, updateWorkdetails, getWorkDetailFormElement, renderWorkDetails)
   getUserDetails() {
  const { userId } = this.state;
  const APIURL = APIS.get_url('GET_USERDETAILS', userId);
  this.setState({ loader: true });
  Axios({
    method: 'get',
    url: APIURL,
    responseType: 'json',
    timeout: 60 * 1000,
    headers: {
      'X-Username': 'santhosh',
    },
  }).then((res) => {
    if (res.data) {
      const permanent_address = res.data?.residential_address;
      let workHistoryData = res.data?.work_history;
      // ... (work history parsing logic) ...

      // Extract divisions from the response
      const divisionsData = res.data?.divisions || []; // Assuming 'divisions' is a top-level key

      this.setState({
        ...res.data,
        permanent_address,
        work_history: workHistoryData,
        divisions: divisionsData, // Set divisions directly in the state
      }, () => {
          console.log("Divisions after getUserDetails:", this.state.divisions);
        if (this.state.welfare) {
          this.getWelfare();
        }
      });
    }
    this.setState({ loader: false });
  });
}
    getWelfare() {
    const { user } = this.state;
    this.setState({ loader: true });
    const APIURL = APIS.get_url('GET_WELFARE', user);
    Axios({
      method: 'get',
      url: APIURL,
      responseType: 'json',
      timeout: 60 * 1000,
      headers: {
        'X-Username': 'santhosh',
      },
    })
      .then((res) => {
  if (res.data) {
    console.log('res.data in getWelfare', res.data);
    const permanent_address = res.data.permanent_address ? res.data.permanent_address : this.state.permanent_address;
    let nomineesData = [];
    if (res.data.nominees) {
      try {
        nomineesData = JSON.parse(res.data.nominees);
        if (!Array.isArray(nomineesData)) {
          console.error('Parsed nominees data is not an array:', nomineesData);
          nomineesData = [];
        }
      } catch (error) {
        console.error('Error parsing nominees JSON:', error);
        nomineesData = [];
      }
    }
    this.setState({
      ...res.data,
      permanent_address,
      user: this.state.user,
      nominees: nomineesData // Ensure we are setting it to the parsed array
    });
    if (res.data?.user) {
      this.setState({ welfarePutMethod: true });
    }
  } else {
    this.setState({ loader: false, nominees: [] });
  }
  this.setState({ loader: false });
})
    }
    getAddExtraWorkBtn() {
        return (
            <div className='add-extra-value'>
                <button className='btn primary' onClick={() => this.addExtraWork()}>
                    Add Extra Work
                </button>
            </div>
        );
    }
    updateFormValue(e, key, parentKeyvalue, nestedKeyvalue, index) {
    const updatedKeyCpy = [...this.state.updatedKey];
    updatedKeyCpy.push(key);

    if (parentKeyvalue && nestedKeyvalue) {
      const parentValue = this.state[parentKeyvalue] || {};
      parentValue[nestedKeyvalue] = parentValue[nestedKeyvalue] || {};
      parentValue[nestedKeyvalue][key] = e.target.value;
      this.setState({
        [parentKeyvalue]: parentValue,
        updatedKey: updatedKeyCpy,
      });
    } else if (index !== undefined) {
      const parentValue = this.state.nominees ? [...this.state.nominees] : []; // Create a copy
      if (parentValue[index]) {
        parentValue[index][key] = e.target.value; 
        this.setState({ nominees: parentValue, updatedKey: updatedKeyCpy }, () => {
          console.log('After setState (nominees):', [...this.state.nominees]);
        });
      }
    } else {
      this.setState({ [key]: e.target.value, updatedKey: updatedKeyCpy });
    }
  }

addExtraWork() {
  this.setState(prevState => ({
    work_history: [...prevState.work_history, {
      doj: '',
      dor: '',
      designation: '',
      office_address: '',
      division: '',
    }]
  }));
}

    uploadProfileImage(imageFile) {
        const file = imageFile[0];
        const reader = new FileReader();
        reader.readAsDataURL(file);
        reader.onloadend = function () {
            const updatedKeyCpy = [...this.state.updatedKey];
            updatedKeyCpy.push('profile_image');
            this.setState({
                profileImageSrc: reader.result,
                profile_image: file,
                updatedKey: updatedKeyCpy,
            });
        }.bind(this);
    }
    removeFile() {
        this.setState({ profileImageSrc: undefined, profile_image: undefined });
    }
    handleOptionChange(value) {
        console.log(value);
    }
    updateUser() {
    this.setState({ loader: true });
    const APIURL = APIS.get_url('UPDATE_USER_DATA_DETAILS', this.state.userId);
    let { work_history, updatedKey } = this.state;
    let currentWorkHistrory = null;

    if (work_history) {
      if (Array.isArray(work_history) && work_history.length > 0) {
        currentWorkHistrory = work_history[0];
      } else if (
        typeof work_history === 'string' &&
        work_history.trim() !== ''
      ) {
        try {
          const parsedHistory = JSON.parse(work_history);
          if (Array.isArray(parsedHistory) && parsedHistory.length > 0) {
            currentWorkHistrory = parsedHistory[0];
            work_history = parsedHistory; // Update local work_history to the parsed array
          } else {
            console.error(
              "Parsed work_history is not a non-empty array:",
              parsedHistory
            );
            this.setState({ loader: false });
            return;
          }
        } catch (error) {
          console.error("Error parsing work_history JSON:", error);
          this.setState({ loader: false });
          return;
        }
      } else {
        console.warn("work_history is null, undefined, or an empty string. No work history to update.");
      }
    } else {
      console.warn("work_history is null or undefined. No work history to update.");
    }

    let formData = new FormData();
    console.log({ updatedKey });

    // Convert updatedKey to a Set to get unique keys
    const uniqueUpdatedKeys = new Set(updatedKey);

    uniqueUpdatedKeys.forEach((key) => {
      console.log({ key, val: this.state[key] });
      if (key !== 'profileImageSrc' && key !== 'work_history') {
        formData.append(key, this.state[key]);
      } else if (key === 'work_history' && currentWorkHistrory) {
        formData.append('work_history', JSON.stringify(work_history));
        formData.append('doj', currentWorkHistrory['doj']);
        formData.append('designation', currentWorkHistrory['designation']);
        formData.append(
          'office_address',
          currentWorkHistrory['office_address']
        );
        formData.append('division', currentWorkHistrory['division']);
      }
    });

    Axios({
      method: 'put',
      url: APIURL,
      data: formData,
      headers: {
        'Content-Type': 'multipart/form-data',
      },
      responseType: 'json',
    })
      .then((res) => {
        if (res) {
          toastEmitter('User Data updated');
        }
        this.setState({ loader: false });
      })
      .catch((error) => {
        console.error("Error updating user data:", error);
        toastEmitter('Error updating user data', 'error');
        this.setState({ loader: false });
      });
  }
    adduser() {        
        this.setState({ loader: true });
        const APIURL = APIS.get_url('ADD_USER');
        let { work_history } = this.state;
        const currentWorkHistrory = work_history[0];
        let formData = new FormData();
        const resetState = {};
        Object.keys(this.state).forEach((key) => {
            if (
                key &&
                key !== 'formElements' &&
                key !== 'profileImageSrc' &&
                key !== 'work_history'
            ) {
                formData.append(key, this.state[key]);
            }
            if (key !== 'formElements') {
                if (key === 'work_history') {
                    resetState[key] = [
                        {
                            doj: '',
                            dor: '',
                            designation: '',
                            office_address: '',
                            division: '',
                        },
                    ];
                } else {
                    resetState[key] = '';
                }
            }
        });
        formData.append('work_history', JSON.stringify(work_history));
        formData.append('doj', currentWorkHistrory['doj']);
        formData.append('designation', currentWorkHistrory['designation']);
        formData.append('office_address', currentWorkHistrory['office_address']);
        formData.append('division', currentWorkHistrory['division']);

        formData.append('name', this.state['first_name'] + this.state['last_name']);
        Axios({
            method: 'post',
            url: APIURL,
            data: formData,
            headers: {
                'Content-Type': 'multipart/form-data',
            },
            responseType: 'json',
        }).then((res) => {
             if (res) {
                toastEmitter('User Data updated');
            }else{
                console.log(res)
                toastEmitter(res)
            }
            this.setState({ loader: false, ...resetState });

        });
    }
    downloadIdCard() {
        window.location.href = this.state.id_card;
    }
    addWelfare() {
        const payloadData = {};
        const {
            nominees,
            gps_cps_no,
            aadhar_no,
            permanent_address,
            married,
            hushband_wife_name,
            user,
            welfarePutMethod
        } = this.state;
        const payloadNominees = [];
        nominees.forEach((nominee) => {
            if (Object.keys(nominee).length > 0) {
                payloadNominees.push(nominee);
            }
        });
        payloadData['nominees'] = JSON.stringify(payloadNominees);
        payloadData['gps_cps_no'] = gps_cps_no;
        payloadData['aadhar_no'] = aadhar_no;
        payloadData['permanent_address'] = permanent_address;
        payloadData['married'] = married;
        payloadData['hushband_wife_name'] = hushband_wife_name;
        this.setState({ loader: true });
        const APIURL = APIS.get_url('ADD_WELFARE', user);
        Axios({
            method: welfarePutMethod ? 'put' : 'post',
            url: APIURL,
            data: payloadData,
            headers: {
                'Content-Type': 'application/json',
            },
            responseType: 'json',
        }).then((res) => {
            toastEmitter('Welfare Data updated');
            this.setState({ loader: false });
        });
    }
    getFormElement(element, parentKeyvalue, nestedKeyvalue, indexValue) {
    if (element.type === 'dropdown') {
      return (
        <DropDown
          dropDownOptions={element.options}
          label={element.label}
          handleOptionChange={(selectedValue) => {
            console.log('change', { selectedValue });
            this.updateFormValue(
              { target: { value: selectedValue } },
              element.key,
              parentKeyvalue,
              nestedKeyvalue,
              indexValue
            );
          }}
          selectedValue={
            indexValue !== undefined
              ? this.state?.nominees?.[indexValue]?.hasOwnProperty(element.key)
                ? this.state?.nominees?.[indexValue]?.[element.key]
                : ''
              : this.state[element.key]
          }
        />
      );
    } else {
      return (
        <TextField
          label={element?.label}
          type={element?.type}
          value={
            indexValue !== undefined
              ? this.state?.nominees?.[indexValue]?.hasOwnProperty(element.key)
                ? this.state?.nominees?.[indexValue]?.[element.key]
                : ''
              : this.state[element.key]
          }
          variant='outlined'
          defaultValue={element.type === 'date' ? '0000-00-00' : ''}
          onChange={(e) =>
            this.updateFormValue(
              e,
              element.key,
              parentKeyvalue,
              nestedKeyvalue,
              indexValue
            )
          }
          size='small'
          InputLabelProps={{ shrink: element.type || this.state.userId }}
        />
      );
    }
  }
    renderFormFields(formFields, parentKeyvalue, nestedKeyvalue, indexValue) {
        return formFields.map((element) =>
            this.getFormElement(element, parentKeyvalue, nestedKeyvalue)
        );
    }
    renderFormCard(element) {
        return (
            <div className='form-holder'>
                <div className='form-holder-header'>{element.formHeader}</div>
                {this.renderFormFields(
                    element.formFields,
                    element.parentKeyvalue,
                    element.nestedKeyvalue
                )}
            </div>
        );
    }

    renderFormHolder() {
        const { formElements } = this.state;
        return formElements.map((element) => this.renderFormCard(element));
    }
    renderWelfareElement() {
    const tableConstants = [
      {
        label: 'Name',
        key: 'name',
      },
      {
        label: 'Relationship',
        type: 'dropdown',
        options: ['Father', 'Mother', 'Spouse', 'Son', 'Daughter'],
        key: 'relationship',
      },
      {
        label: 'D.O.B',
        type: 'date',
        key: 'dob',
      },
    ];
    console.log("------------------------------")
    console.log(this.state.nominees)
    return (
      <table className='Data-Table'>
        <thead>
          <tr>
            {tableConstants.map((constant) => (
              <th key={constant.key}>{constant.label}</th>
            ))}
          </tr>
        </thead>
        <tbody>
          {this.state.nominees && this.state.nominees.map((nominee, index) => (
            <tr key={index}>
              {tableConstants.map((constant) => (
                <td key={`${index}-${constant.key}`}>
                 {this.getFormElement(constant, 'nominees', undefined, index)}
                </td>
              ))}
            </tr>
          ))}
          {/* Optionally render extra rows for adding new nominees */}
          {this.state.nominees && this.state.nominees.length < 5 &&
            Array.from({ length: 5 - this.state.nominees.length }).map((_, index) => (
              <tr key={`empty-${this.state.nominees.length + index}`}>
                {tableConstants.map((constant) => (
                  <td key={`empty-${this.state.nominees.length + index}-${constant.key}`}>
                    {this.getFormElement(constant, 'nominees', undefined, this.state.nominees.length + index)}
                  </td>
                ))}
              </tr>
            ))}
        </tbody>
      </table>
    );
  }

    updateWorkdetails(e, key, index) {
        const { work_history, updatedKey } = this.state;
  if (!Array.isArray(work_history)) {
    console.error("Error: work_history is not an array:", work_history);
    return;
  }
        const updatedKeyCpy = [...updatedKey];
        if (updatedKeyCpy.indexOf('work_history') < 0) {
            updatedKeyCpy.push('work_history');
        }

        work_history[index][key] = e.target.value; // Error here
        this.setState({ work_history, updatedKey: updatedKeyCpy });
    }
    getWorkDetailFormElement(label, value, key, index, type, options) {
        switch (type) {
            case 'dropdown':
                return (
                      <DropDown
                        dropDownOptions={options}
                        label={label}
                        handleOptionChange={(e) => this.updateWorkdetails(e, key, index)} // 'e' is directly passed
                        selectedValue={value}
                    />
                );
                break;
            default:
                return (
                    <TextField
                        label={label}
                        type={type}
                        value={value}
                        variant='outlined'
                        onChange={(e) => this.updateWorkdetails(e, key, index)}
                        size='small'
                        InputLabelProps={{ shrink: type }}
                    />
                );
        }
    }

renderWorkDetails() {
  const { work_history, userId, division: stateDivisions } = this.state; // Alias state.divisions for clarity
  let parsedWorkHistory = [];

  

  if (work_history && typeof work_history === 'string') {
    try {
      parsedWorkHistory = JSON.parse(work_history);
    //   stateDivisions=parsedWorkHistory.division
      console.log("Parsed work_history:", parsedWorkHistory);
    } catch (error) {
      console.error("Error parsing work_history JSON:", error);
      return null;
    }
  } else if (Array.isArray(work_history)) {
    parsedWorkHistory = work_history;
    console.log("work_history is already an array:", parsedWorkHistory);
  } else {
    console.log("work_history is neither string nor array:", work_history);
    return null;
  }

  return (
    parsedWorkHistory.map((workdetail, idx) => {
      console.log("Current workdetail:", workdetail);
      console.log("workdetail.office_address (type):", typeof workdetail.office_address, workdetail.office_address);
      console.log("Current workdetail:", workdetail);
      console.log("Divisions in render (state):", stateDivisions);
      console.log("Divisions in render (constant):", divisions);

      const circleOfWorkingOptions = userId && Array.isArray(stateDivisions)
        ? stateDivisions.map((div) => div.edc)
        : divisions.map((div) => div.edc);

      const getOfficeAddressOptions = (selectedDivision) => {
        const sourceDivisions = userId && Array.isArray(stateDivisions) ? stateDivisions : divisions;
        return sourceDivisions.find((div) => div.edc === selectedDivision)?.sections || [];
      };

      const officeAddressOptions = getOfficeAddressOptions(workdetail.division);

      return (
        <div className='form-holder' key={idx}>
          <div className='form-holder-header'>Work Details</div>
          {this.getWorkDetailFormElement(
            'Joining Date',
            workdetail.doj,
            'doj',
            idx,
            'date'
          )}
          {idx !== 0 &&
            this.getWorkDetailFormElement(
              'Relieving Date',
              workdetail.dor,
              'dor',
              idx,
              'date'
            )}
          {this.getWorkDetailFormElement(
            'Designation',
            workdetail.designation,
            'designation',
            idx
          )}
          {this.getWorkDetailFormElement(
            'Circle Of Working ',
            workdetail.division,
            'division',
            idx,
            'dropdown',
            circleOfWorkingOptions
          )}
          {this.getWorkDetailFormElement(
            'Office Address',
            String(workdetail.office_address),
            'office_address',
            idx,
            'dropdown',
            officeAddressOptions
          )}
          {idx === parsedWorkHistory.length - 1 && this.getAddExtraWorkBtn()}
        </div>
      );
    })
  );
}

    render() {
        const profileImgSrc = this.state.profileImageSrc
            ? this.state.profileImageSrc
            : this.state.profile_image;
        return (
            <div className='add-user'>
                {this.state.loader && <div className='loader'></div>}
                <div className='content-area'>
                    <div className='form-navbar'>
                        <div className='page-header'>Registration Form</div>
                        <div className='navbar-info'>Enter user info to be registered</div>
                        {profileImgSrc ? (
                            <div className='csv-dropzone-solid'>
                                <img src={profileImgSrc} />
                                <div
                                    className='close'
                                    onClick={() => {
                                        this.removeFile();
                                    }}
                                >
                                    <Icon name='close' />
                                </div>
                            </div>
                        ) : (
                            <div className='csv-dropzone'>
                                <DropZone
                                    showCenterText={true}
                                    showIcon={true}
                                    onDrop={(files) => this.uploadProfileImage(files)}
                                />
                            </div>
                        )}
                        {this.state.id_card && (
                            <div className='id__card' onClick={() => this.downloadIdCard()}>
                                {' '}
                                Download Id Card
                            </div>
                        )}
                    </div>
                    <div className='form-area'>
                        {this.renderFormHolder()}
                        {!this.state.welfare && this.renderWorkDetails()}
                        {this.state.welfare && this.renderWelfareElement()}
                    </div>
                </div>
                <div className='button-holder'>
                    <button className='btn'>Reset</button>
                    {this.state.welfare ? (
                        <button className='btn primary' onClick={() => this.addWelfare()}>
                            Submit
                        </button>
                    ) : (
                        <button
                            className='btn primary'
                            onClick={() => {
                                this.state.userId ? this.updateUser() : this.adduser();
                            }}
                        >
                            {this.state.userId ? 'Update' : 'Submit'}
                        </button>
                    )}
                </div>
            </div>
        );
    }
}

export default withRouter(AddUser);